.preloader-wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    background: white;
    z-index: 1000;
}

.preloader-7 {
    margin: 10vh auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader-7 div {
    border: 4px inset #FFF;
    border-radius: 100%;
    padding: 2px;
    animation: preloader-7-spin 4s linear infinite;
}
@keyframes preloader-7-spin {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}
.header__adaptive-menu-layout {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in;
}

.header__adaptive-menu-layout--active {
    opacity: 1;
    pointer-events: all;
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(36, 36, 38, 0.7);
}

.header__tablet-menu {
    display: none;
}

.header__mobile-menu {
    display: none;
}

@media (max-width: 1500px) {
    .header__tablet-close {
        position: absolute;
        z-index: 1000;
        top: 20px;
        right: 20px;
        width: 56px;
        height: 56px;
        cursor: pointer;
    }

    .header__tablet-close::before,
    .header__tablet-close::after {
        content: "";
        position: absolute;
        bottom: 50%;
        left: 50%;
        width: 32px;
        height: 2px;
        background-color: #242426;
    }
    .header__tablet-close::before {
        transform: translate(-50%, -50%) rotate(45deg);
    }
    .header__tablet-close::after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    .header__tablet-menu {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 276px;
        padding-top: 126px;
        padding-right: 35px;
        padding-left: 41px;
        background-color: #fff;
        transform: translateX(100%);
        transition: all 0.5s;
        z-index: 999;
    }

    .header__tablet-menu_active {
        transform: translateX(0);
        transition: all 0.5s;
    }

    .header__nav-tablet-list {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .header__nav-tablet {
    }

    .header__nav-adapt-li {
    }
    .header__nav-adapt-link {
        position: relative;
        display: flex;
        transform: translateX(49px);
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 1.2;
        text-transform: capitalize;
        color: #242426;
        cursor: pointer;
        transition: 0.25s ease-in;
    }

    .header__nav-adapt-link:hover {
        color: #604fe3;
        transform: translateX(0);
    }

    .header__nav-adapt-arrow {
        position: relative;
        display: inline-block;
        width: 41px;
        height: 1px;
        opacity: 0;
        background-color: #604fe3;
        transition: 0.25s ease-in;
        margin-left: 8px;
    }

    .header__nav-adapt-arrow::before,
    .header__nav-adapt-arrow::after {
        content: "";
        position: absolute;
        width: 6px;
        height: 1px;
        background-color: #604fe3;
        transition: 0.25s ease-in;
    }

    .header__nav-adapt-arrow::before {
        top: 2px;
        right: -2px;
        transform: rotate(-45deg);
    }

    .header__nav-adapt-arrow::after {
        top: -2px;
        right: -2px;
        transform: rotate(45deg);
    }

    .header__nav-adapt-link--active::after {
        content: "";
        position: absolute;
        top: 9px;
        right: -11px;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background: #604fe3;
    }

    .header__nav-adapt-link:hover .header__nav-adapt-arrow {
        opacity: 1;
    }

    .header__nav-adapt-link--active {
        position: relative;
        transform: translateX(0);
    }

    .header__nav-adapt-link--active .header__nav-adapt-arrow {
        display: none;
    }

    .header__nav-adapt-li {
        margin-bottom: 12px;
    }
    .header__nav-adapt-li:last-child {
        margin-bottom: 42px;
    }
    .header__phone-adapt {
        display: block;
        font-weight: 600;
        font-size: 18px;
        line-height: 1.2;
        letter-spacing: 0.03em;
        color: #242426;
        text-align: right;
        margin-bottom: 24px;
        transition: color 0.25s ease-in;
    }

    .header__phone-adapt:hover {
        color: #604fe3;
    }

    .header__order-btn-adapt {
        display: block;
        width: 100%;
        padding: 10px 15px;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.25;
        text-align: center;
        color: #ffffff;
    }
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
    .header__adaptive-menu-layout--active {
        z-index: 998;
    }

    .header-wrapper--home .header__adaptive-menu-layout {
        background-color: #3a2dd1;
        background-image: url(../../images/header/mobile-menu-gradient.svg);
    }

    .header--active {
        border-bottom: none;
    }

    .header-wrapper--home .header--active {
        background-color: transparent;
        border-bottom: 2px solid rgba(255, 255, 255, 0.3);
    }

    .header__mobile-menu {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1005;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 200px 22px 140px;
        background-color: #ffffff;
        transform: translateY(-100%);
        transition: 0.5s ease-in;
    }

    .header-wrapper--home .header__mobile-menu {
        background-color: transparent;

    }
    .header__mobile-menu_active {
        top: 45px;
        transform: translateY(0);
    }
    .header__nav-mobile {
    }
    .header__nav-mobile-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .header__nav-adapt-li {
        margin-bottom: 11px;
    }
    .header__nav-adapt-li:last-child {
        margin-bottom: 40px;
    }
    .header__nav-adapt-link {
        font-weight: 500;
        font-size: 16px;
        line-height: 1.25;
        text-transform: capitalize;
        color: #242426;
    }

    .header-wrapper--home .header__nav-adapt-link {
        color: white;
    }

    .header-wrapper--home .header__nav-adapt-link:hover {
     color: #A99DFF;
        border-bottom: 1px solid #A99DFF;
    }

    .header__nav-adapt-arrow {
        position: absolute;
        width: 21px;
        right: -29px;
        top: 10px;
        transform: translateX(0);
    }

    .header__nav-adapt-arrow::before,
    .header__nav-adapt-arrow::after {
        width: 6px;
    }

    .header__nav-adapt-link {
        transform: translateX(0);
        border-bottom: 1px solid transparent;
    }

    .header__nav-adapt-link:hover {
        border-bottom: 1px solid #604fe3;
    }

    .header__phone-adapt {
        font-weight: 600;
        font-size: 16px;
        line-height: 1.25;
        letter-spacing: 0.03em;
        color: #242426;
        margin-bottom: 18px;
    }

    .header-wrapper--home .header__phone-adapt {
        color: white;
    }

    .header__order-btn-adapt {
        width: 100%;
        padding: 14px 20px;
        font-size: 14px;
    }
}

@media (max-width: 359px) {
}

.advantages {
    position: relative;
    overflow: hidden;
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
}

.advantages__ellipse {
    position: absolute;
    bottom: -39px;
    left: -324px;
}

.advantages__container {
    display: flex;
    justify-content: space-between;
}
.advantages__heading {
    max-width: 583px;
}
.advantages__items {
}
.advantages__item {
    position: relative;
    max-width: 587px;
    margin-bottom: 32px;
}

.advantages__item:last-child {
    margin-bottom: 0;
}
.advantages__item-arrow {
    position: absolute;
    left: -56px;
    top: 50%;
    transform: translate(-100%, -50%);
    display: block;
    height: 2px;
    width: 144px;
    background-color: #242426;
}

.advantages__item:nth-child(2n) .advantages__item-arrow {
    width: 72px;
}

.advantages__item-arrow::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    top: -4px;
    right: -3px;
    background-color: #242426;
    transform: rotate(45deg);
}
.advantages__item-arrow::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    top: 3px;
    right: -3px;
    background-color: #242426;
    transform: rotate(-45deg);
}

.advantages__item-description {
    font-size: 24px;
    line-height: 140%;
    color: #242426;
}

@media (max-width: 1700px) {
    .advantages__item {
        max-width: 508px;
    }

    .advantages__ellipse {
        left: -371px;
    }
}

@media (max-width: 1500px) {
    .advantages {
        overflow: visible;
    }

    .advantages__container {
        flex-direction: column;
        align-items: flex-end;
        justify-content: unset;
        padding-bottom: 70px;
    }

    .advantages__heading {
        max-width: 505px;
        margin-bottom: 36px;
    }

    .advantages__ellipse {
        width: 776px;
        bottom: -223px;
        left: -288px;
    }
}

@media (max-width: 1200px) {
    .advantages__ellipse {
        width: 601px;
        bottom: -160px;
        left: -233px;
    }

    .advantages__item-description {
        font-size: 23px;
    }

    .advantages__ellipse {
        left: -241px;
    }
}

@media (max-width: 992px) {
    .advantages__container {
        padding-bottom: 50px;
    }
    .advantages__heading {
        max-width: 50%;
    }

    .advantages__items {
        width: 50%;
    }

    .advantages__item {
        max-width: 100%;
        margin-bottom: 12px;
    }

    .advantages__item-description {
        font-size: 14px;
    }

    .advantages__item-arrow {
        left: -51px;
        height: 1px;
        width: 98px;
    }

    .advantages__item:nth-child(2n) .advantages__item-arrow {
        width: 44px;
    }

    .advantages__item-arrow::before {
        width: 6px;
        height: 1px;
        top: 2px;
        right: -2px;
    }

    .advantages__item-arrow::after {
        width: 6px;
        height: 1px;
        top: -2px;
        right: -2px;
    }

    .advantages__ellipse {
        left: -223px;
        width: 549px;
        bottom: -170px;
    }
}

@media (max-width: 767px) {
    .advantages__ellipse {
        left: -168px;
        width: 412px;
        bottom: -129px;
    }
}

@media (max-width: 575px) {
    .advantages__container {
        align-items: unset;
        padding-bottom: 100px;
    }

    .advantages__heading {
        max-width: 100%;
        margin-bottom: 24px;
    }

    .advantages__items {
        width: 100%;
    }

    .advantages__item {
        margin-bottom: 34px;
    }

    .advantages__item-arrow {
        width: 128px;
        transform: unset;
        left: -16px;
        top: unset;
        bottom: -8px;
    }

    .advantages__item:nth-child(2n) .advantages__item-arrow {
        width: 80px;
    }

    .advantages__ellipse {
        height: 170px;
        left: unset;
        right: -138px;
        bottom: -106px;
    }
}

@media (max-width: 359px) {
}

.modal {
    position: fixed;
    z-index: 10000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
}

.modalActive {
    opacity: 1;
    pointer-events: all;
    overflow: hidden;
}

.modal__content {
    max-height: 95%;
    max-width: 95%;
    transform: scale(0.5);
    transition: transform 0.2s ease-in;
}

.modal__contentActive {
    transform: scale(1);
}

.modal__close {
    position: absolute;
    right: 0;
    top: 0;
    width: 59px;
    height: 59px;
}

.modal__close:before,
.modal__close:after {
    content: " ";
    position: absolute;
    top: 17px;
    right: 28px;
    height: 23px;
    width: 2px;
    background-color: #2b2a39;
}

.modal__close:before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.modal__close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}

@media (max-width: 992px) {
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
    .modal--mobile-bottom {
        align-items: flex-end;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .modal__content--mobile-bottom {
        max-width: 100%;
    }
}

@media (max-height: 612px) {
    .modal--mobile-bottom {
        align-items: flex-start;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    .modal__content--mobile-bottom {
        max-width: 100%;
        max-height: none;
    }
}

@media (max-width: 359px) {
}

.arrow {
    position: relative;
    display: block;
    width: 30px;
    height: 2px;
    background-color: #242426;
    transition: background-color 0.25s ease-in;
}

.arrow::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    top: -3px;
    right: -3px;
    background-color: #242426;
    transform: rotate(45deg);
    transition: background-color 0.25s ease-in;
}


.arrow::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    top: 3px;
    right: -3px;
    background-color: #242426;
    transform: rotate(-45deg);
    transition: background-color 0.25s ease-in;
}

.arrow--left::after {
    top: -3px;
    left: -3px;
    transform: rotate(-45deg);
}

.arrow--left::before {
    top: 3px;
    left: -3px;
    transform: rotate(45deg);
}

.arrow--white, .arrow--white::after, .arrow--white::before {
    background-color: #fff;
}
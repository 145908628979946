.policy-page {
    position: relative;
}

.policy {
    color: #75767E;
    font-size: 18px;
    line-height: 140%;
    display: flex;
    gap: 45px;
}

.policy h1 {
    margin-bottom: 30px;
}

.policy h3 {
    font-weight: bold;
    color: #242426;
    margin-top: 18px;
    margin-bottom: 10px;
}

.policy__btn-back {
    position: fixed;
    width: 302px;
    padding: 21px 32px;
    font-size: 18px;
    transition: none;
    bottom: 48px;
}

.policy__btn-arrow {
    width: 47px;
    margin-right: 24px;
}

.policy__btn-arrow::before {
    width: 9px;
    height: 2px;
    top: 3px;
    right: -2px;
}

.policy__btn-arrow::after {
    width: 9px;
    height: 2px;
    top: -3px;
    right: -2px;
}

.policy__btn-back--mobile-bottom {
    display: none;
}

.policy__btn-container {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: auto;
    right: auto;
    padding: 12px 0;
    background: white;
}

@media (min-width: 1499px) {
    .policy__btn-container {
        display: none !important;
    }
}

@media (max-width: 1499px) {
    .policy {
        flex-direction: column;
        gap: 0;
    }

    .policy__btn-back {
        display: none;
        position: fixed;
        z-index: 2;
        bottom: 10px;
        right: 10px;
    }

    .policy__btn-container {
        display: block;
    }

    .policy__btn-back--mobile-bottom {
        position: relative;
        display: flex;
        margin-top: 40px;
        margin-left: auto;
        top: 0;
        left: 0;
    }

    .policy__btn-back--mobile {
        position: relative;
        display: flex;
        margin-left: auto;
        top: 0;
        left: 0;
    }
}

@media (max-width: 575px) {
    .policy {
        font-size: 14px;
    }

    .policy__btn-back {
        font-size: 14px;
        line-height: 1.2;
        height: 50px;
        width: 100%;
        justify-content: center;
    }

    .policy__btn-arrow {
        display: none;
    }
}
@import 'mixins.scss';




.section--flex {
    display: flex;
    justify-content: space-between;
}

.section__name {
    max-width: 25%;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: bold;
    @include adaptive-font(32, 14);
    line-height: 1.21;
    letter-spacing: 0.04em;
    text-transform: lowercase;
}

.section__content {
    width: 65.6875%;
    min-width: 65.6875%;
}



.wrapper__section-mt {
    margin-top: 160px;
}



.wrapper__first-section {
    display: block;
}

.wrapper__header {

}

.wrapper__first-section {

}

.wrapper__about-company {
    padding-top: 80px;
}

.section__content-wrapper {
    
}


@media (max-width: 1200px) {
    .section__content {
        width: 69%;
    }
}

@media (max-width: 768px) {
    .section--flex {
        flex-direction: column;
    }

    .section__content {
        width: 100%;
    }


    .section__name {
        margin-bottom: 9.75%;
    }

    .wrapper__section-mt {
        margin-top: 80px;
    }

}
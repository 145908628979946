.why-we {
}
.why-we__heading {
    margin-bottom: 63px;
}
.why-we__items {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    border: 2px solid #75767e;
    border-radius: 4px;
}
.why-we__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
    width: 50%;
    padding: 24px 24px 33px 24px;
    overflow: hidden;
}

.why-we__item:nth-child(1) {
    border-right: 2px solid #75767e;
    border-bottom: 2px solid #75767e;
}
.why-we__item:nth-child(2) {
    border-bottom: 2px solid #75767e;
}
.why-we__item:nth-child(3) {
    border-right: 2px solid #75767e;
}
.why-we__item:nth-child(4) {
}
.why-we__item-img {
    position: absolute;
}

.why-we__item:nth-child(1) .why-we__item-img {
    top: -234px;
    right: -132px;
    max-width: none;
    width: 614px;
}
.why-we__item:nth-child(2) .why-we__item-img {
    top: -74px;
    right: -140px;
    max-width: none;
    width: 750px;
}
.why-we__item:nth-child(3) .why-we__item-img {
    top: -92px;
    right: -45px;
    max-width: none;
    width: 479px;
}
.why-we__item:nth-child(4) .why-we__item-img {
    top: -33px;
    right: -105px;
    max-width: none;
    width: 700px;
}

.why-we__item-number {
    font-weight: 700;
    font-size: 64px;
    line-height: 130%;
    color: #242426;
}

.why-we__text-container {
    width: 424px;
}

.why-we__item-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 120%;
    color: #242426;
    margin-bottom: 18px;
}
.why-we__item-description {
    font-size: 16px;
    line-height: 130%;
    color: #242426;
}

.why-we__item-number-mobile {
    display: none;
}

@media (max-width: 1700px) {
    .why-we__item:nth-child(1) .why-we__item-img {
        top: -204px;
        right: -150px;
        width: 550px;
    }
    .why-we__item:nth-child(2) .why-we__item-img {
        top: -49px;
        right: -170px;
        width: 681px;
    
    }
    .why-we__item:nth-child(3) .why-we__item-img {
        top: -96px;
        right: -100px;
    }
    .why-we__item:nth-child(4) .why-we__item-img {
        top: -31px;
        right: -166px;
        max-width: none;
        width: 675px;
    }
}

@media (max-width: 1500px) {
    .why-we__heading {
        margin-bottom: 49px;
    }

    .why-we__text-container {
        width: 100%;
    }

    .why-we__item-title {
        font-size: 30px;
    }

    .why-we__item:nth-child(1) .why-we__item-img {
        top: -130px;
        right: -127px;
        width: 442px;
    }
    .why-we__item:nth-child(2) .why-we__item-img {
        top: -15px;
        right: -191px;
        max-width: none;
        width: 523px;
    }
    .why-we__item:nth-child(3) .why-we__item-img {
        top: -67px;
        right: -106px;
        width: 407px;
    }
    .why-we__item:nth-child(4) .why-we__item-img {
        top: -6px;
        right: -185px;
        max-width: none;
        width: 530px;
    }

    .why-we__item-number {
        font-size: 54px;
    }
}

@media (max-width: 1200px) {
    .why-we__item {
        height: 335px;
    }

    .why-we__item-title {
        font-size: 24px;
        margin-bottom: 8px;
    }

    .why-we__item:nth-child(1) .why-we__item-img {
        top: -124px;
        right: -111px;
        width: 358px;
    }
    .why-we__item:nth-child(2) .why-we__item-img {
        top: -15px;
        right: -108px;
        max-width: none;
        width: 394px;
    }
    .why-we__item:nth-child(3) .why-we__item-img {
        top: -65px;
        right: -81px;
        width: 324px;
    }
    .why-we__item:nth-child(4) .why-we__item-img {
        top: -12px;
        right: -122px;
        max-width: none;
        width: 427px;
    }

    .why-we__item-number {
        font-size: 41px;
    }
}

@media (max-width: 992px) {
    .why-we__heading {
        margin-bottom: 37px;
    }

    .why-we__item {
        height: 278px;
        padding: 18px 30px 22px 18px;
    }

    .why-we__item-title {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .why-we__item-description {
        font-size: 14px;
    }

    .why-we__item:nth-child(1) .why-we__item-img {
        top: -98px;
        right: -74px;
        max-width: none;
        width: 311px;
    }
    .why-we__item:nth-child(2) .why-we__item-img {
        top: -17px;
        right: -76px;
        max-width: none;
        width: 345px;
    }
    .why-we__item:nth-child(3) .why-we__item-img {
        top: -39px;
        right: -19px;
        max-width: none;
        width: 250px;
    }
    .why-we__item:nth-child(4) .why-we__item-img {
        top: -12px;
        right: -85px;
        max-width: none;
        width: 360px;
    }

    .why-we__item-number {
        font-size: 18px;
    }
}

@media (max-width: 880px) {
    .why-we__item:nth-child(1) .why-we__item-img {
        top: -90px;
        right: -74px;
        max-width: none;
        width: 263px;
    }
    .why-we__item:nth-child(2) .why-we__item-img {
        top: -28px;
        right: -91px;
        max-width: none;
        width: 322px;
    }
    .why-we__item:nth-child(3) .why-we__item-img {
        top: -39px;
        right: -28px;
        max-width: none;
        width: 213px;
    }
    .why-we__item:nth-child(4) .why-we__item-img {
        top: -13px;
        right: -54px;
        max-width: none;
        width: 280px;
    }
}

@media (max-width: 767px) {
    .why-we__heading {
        margin-bottom: 29px;
    }

    .why-we__item {
        height: 301px;
        padding: 18px 30px 22px 18px;
    }

    .why-we__item-title {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .why-we__item-description {
        font-size: 14px;
    }

    .why-we__item:nth-child(1) .why-we__item-img {
        top: -84px;
        right: -74px;
        max-width: none;
        width: 244px;
    }
    .why-we__item:nth-child(2) .why-we__item-img {
        top: -12px;
        right: -91px;
        max-width: none;
        width: 278px;
    }
    .why-we__item:nth-child(3) .why-we__item-img {
        top: -39px;
        right: -28px;
        max-width: none;
        width: 204px;
    }
    .why-we__item:nth-child(4) .why-we__item-img {
        top: -9px;
        right: -90px;
        max-width: none;
        width: 285px;
    }
    .why-we__item-number {
        font-size: 18px;
    }
}

@media (max-width: 575px) {

    .why-we__heading {
        margin-bottom: -6px;
    }

    .why-we__items {
        flex-direction: column;
        border: none;
    }

    .why-we__item {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: baseline;
        width: 100%;
        height: auto;
        padding: 0;
        margin-bottom: 36px;
    }
    
    .why-we__item:nth-child(1), .why-we__item:nth-child(2), .why-we__item:nth-child(3){
        border-right: none;
        border-bottom: none;
    }

    .why-we__item:nth-child(1) .why-we__text-container {
        margin-top: -19px;
    }
    .why-we__item-title {
        font-size: 18px;
        line-height: 140%;
        font-weight: 600;
        margin-bottom: 10px;
    }

    .why-we__item-description {
        padding-left: 20px;
        font-size: 14px;
        line-height: 130%;
    }

    .why-we__item-img {
        position: static;
        padding-left: 20px;
        margin-bottom: 16px;
    }

    .why-we__item:nth-child(1) .why-we__item-img {
        width: 225px;
    }
    .why-we__item:nth-child(2) .why-we__item-img {
        width: 227px;
    }
    .why-we__item:nth-child(3) .why-we__item-img {
        width: 235px;
    }
    .why-we__item:nth-child(4) .why-we__item-img {
        width: 253px;
    }
    .why-we__item-number {
        display: none;
    }
    .why-we__item-number-mobile {
        display: inline-block;
        width: 12px;
        margin-right: 8px;
        font-size: 18px;
        
    }
}

@media (max-width: 359px) {
}

.reviews {
    margin-top: 100px;
    overflow: hidden;
}

.reviews__slider {
    width: 1761px;
}

.reviews__heading {
    margin-bottom: 36px;
}

.reviews__slide {
    width: 1044px;
    max-width: 100%;
    padding: 50px 60px 44px 46px;
    border: 2px solid #242426;
    border-right: none;
    border-radius: 4px 0px 0px 4px;
    display: flex;
    gap: 80px;
    justify-content: space-between;
}

.reviews__slide:last-child {
    border-right: 2px solid #242426;
}

.reviews__slide-title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 110%;
    color: #242426;
    min-width: 31%;
}

.reviews__slide-content {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #242426;
}

.reviews__navigation {
    position: relative;
    display: flex;
    width: 100%;
    gap: 98px;
    justify-content: right;
    margin-top: 24px;
    margin-right: auto;
}
.long-slider-arrow {
    position: relative;
    padding-bottom: 12px;
    display: block;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #242426;
    transition: .3s;
    cursor: pointer;
}

.long-slider-arrow--disabled {
    opacity: .5;
    z-index: -1;
}

.long-slider-arrow:hover {
    color: #604FE3;
}

.long-slider-arrow::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: currentColor;
}

.long-slider-arrow::after {
    content: '';
    position: absolute;
    bottom: -4px;
    width: 10px;
    height: 10px;
    border-top: 2px solid currentColor;
    border-right: 2px solid currentColor;
}

.long-slider-arrow-next::after {
    transform: rotate(45deg);
    right: 0;
}

.long-slider-arrow-prev::after {
    transform: rotate(-135deg);
    left: 0;
}

@media (max-width: 1500px) {
    
    .reviews__slide {
        width: 900px;
    }

    .reviews__slide-title {
        font-size: 18px;
    }

    .reviews__slide-content {
        font-size: 14px;
    }

    .reviews__navigation {
        max-width: 900px;
    }
    
}

@media (max-width: 1200px) {
    .reviews__slide {
        max-width: 833px;
        padding: 24px;
        gap: 24px;
    }

    .reviews__slide-title {
        font-size: 18px;
    }

    .reviews__slide-content {
        font-size: 14px;
    }

    .long-slider-arrow {
        padding-bottom: 8px;
    }

    .long-slider-arrow::before {
        height: 1px;
    }

    .long-slider-arrow::after {
        content: '';
        position: absolute;
        bottom: -2px;
        width: 6px;
        height: 6px;
        border-top: 1px solid currentColor;
        border-right: 1px solid currentColor;
    }

    .reviews__navigation {
        max-width: 833px;
    }
    
}

@media (max-width: 992px) {
    .reviews__heading {
        margin-bottom: 24px;
    }

    .reviews__slider {
        width: calc(100vw - 80px);
    }

    .reviews__slide {
        max-width: calc(100% - 80px);
    }

    .reviews__navigation {
        max-width: calc(100% - 80px);
        margin-top: 13px;
        gap: 50px;
    }

    .long-slider-arrow {
        font-size: 12px;
    }
}

@media (max-width: 767px) {

    .reviews__slider {
        width: calc(100vw - 40px);
    }

    .reviews__navigation {
        max-width: calc(100% - 40px);
    }
}

@media (max-width: 680px) {
    .reviews__slide {
        flex-direction: column;
        gap: 8px;
    }
}

@media (max-width: 767px) {
    .reviews__slide {
        max-width: calc(100% - 40px);
    }
}
.header-wrapper {
    position: fixed;
    z-index: 901;
    right: 0;
    left: 0;
}

.header {
    transition: 0.25s ease-in;
}

.header--white {
    background-color: #fff;
    border-bottom: 2px solid rgba(36, 36, 38, 0.3);
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    transition: 0.25s ease-in;
}

.header__logo {
    margin-top: 8px;
}

.header__logo-container {
    display: flex;
    align-items: center;
}

.header__logo-img {
    width: 51px;
    margin-right: 9px;
}

.header__logo-text {
    color: white;
    font-size: 18px;
    line-height: 1.2;
    text-transform: uppercase;
}

.header__inner-wrapper {
    display: flex;
    align-items: center;
}

.header__nav {
}

.header__nav-list {
    display: flex;
}

.header__nav-li {
    margin-right: 55px;
}

.header__nav-li:last-child {
    margin-right: 116px;
}

.header__nav-link {
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2;
    text-transform: capitalize;
    color: #242426;
    cursor: pointer;
}

.header__phone {
    flex-shrink: 0;
    margin-right: 57px;
    font-weight: 500;
    font-size: 17px;
    line-height: 1.2;
    text-transform: lowercase;
    color: #242426;
}

.header__burger-btn {
    display: none;
}

.header__order-btn {
    padding: 10px 18px;
    font-weight: 600;
    font-size: 18px;
    line-height: 1.2;
}

.header--white-text .header__logo {
    color: white;
}
.header--white-text .header__nav-link {
    color: #ffffff;
}
.header--white-text .header__phone {
    color: #ffffff;
}

.header--white .header-container {
    padding-top: 2px;
    padding-bottom: 2px;
}
.header--white .header__logo {
    color: #242426;
}
.header--white .header__nav-link {
    color: #242426;
}
.header--white .header__phone {
    color: #242426;
}

.header-wrapper--home .header__logo {
    color:white;
}

.header__mobile-gradient {
    display:none;
}
   


@media (max-width: 1500px) {
    .header__nav-li:last-child {
        margin-right: 0;
    }

    .header__nav {
        display: none;
    }

    .header__order-btn {
        display: none;
    }

    .header__burger-btn {
        display: block;
        position: relative;
        z-index: 101;
        width: 56px;
        height: 56px;
        margin-left: 16px;
    }

    .header__burger-btn-line {
        position: absolute;
        top: 48%;
        right: 21.5%;
        width: 24px;
        height: 2px;
        border-radius: 6px;
        background-color: #242426;
    }

    .header__burger-btn::after,
    .header__burger-btn::before {
        content: "";
        position: absolute;
        left: 50%;
        height: 2px;
        width: 32px;
        border-radius: 6px;
        background-color: #242426;
        transform: translateX(-50%);
        transition: transform 0.2s ease-in;
    }

    .header__burger-btn::after {
        top: 32%;
    }

    .header__burger-btn::before {
        top: 64%;
    }

    .header--white .header-container {
        padding-top: 2px;
        padding-bottom: 2px;
    }

    .header--white-text .header__burger-btn-line,
    .header--white-text .header__burger-btn::after,
    .header--white-text .header__burger-btn::before {
        background-color: #fff;
    }

    .header--white .header__burger-btn-line,
    .header--white .header__burger-btn::after,
    .header--white .header__burger-btn::before {
        background-color: #242426;
    }
}

@media (max-width: 992px) {
    .header__phone {
        font-size: 18px;
        margin-right: 20px;
    }

    .header__burger-btn {
        margin-left: 0;
    }

    .header__burger-btn::before {
        top: 62%;
    }

    .header__burger-btn::after {
        top: 34%;
    }
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
    .header-wrapper {
        top: 0;
    }

    .header {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1010;
    }

    .header-container {
        position: relative;
        z-index: 1000;
    }

    .header--white {
        border-bottom: 2px solid rgba(36, 36, 38, 0.3);
    }
    .header__phone {
        display: none;
    }

    .header-container {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .header__logo {
        width: 101px;
        height: 32px;
        margin-top: 5px;
    }

    .header__burger-btn {
        width: 38px;
        height: 38px;
    }

    .header__burger-btn::after {
        top: 20%;
    }

    .header__burger-btn-line {
        top: 40%;
        right: 8%;
    }

    .header__burger-btn::before {
        top: 62%;
    }

    .header__burger-btn--active .header__burger-btn-line {
        display: none;
    }
    .header__burger-btn--active::after {
        top: 11%;
        transform-origin: 100% 0 0;
        transform: translateX(-50%) rotate(-45deg);
        transition: transform 0.4s ease-in;
    }
    .header__burger-btn--active::before {
        top: 71%;
        transform-origin: 100% 0 0;
        transform: translateX(-50%) rotate(45deg);
        transition: transform 0.4s ease-in;
    }

    .header-wrapper--home .header__burger-btn--active .header__burger-btn-line,
    .header-wrapper--home .header__burger-btn--active::after,
    .header-wrapper--home .header__burger-btn--active::before {
        background-color: #fff;
    }

    .header__mobile-gradient {
        display:block;
        position: absolute;
        z-index: 999;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

@media (max-width: 359px) {
}

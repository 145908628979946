.heading-service {
    font-weight: 600;
    font-size: 40px;
    line-height: 115%;
    color: #242426;
}
@media (max-width: 1200px) {

    .heading-service {
        font-size: 33px;
    }

}
@media (max-width: 992px) {
    .heading-service {
        font-size: 24px;
    }
 
}

@media (max-width: 767px) {
  
}

@media (max-width: 575px) {
    .heading-service {
        font-size: 18px;
    }
  
}

@media (max-width: 359px) {
  
}
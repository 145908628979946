.project-page {
    padding-top: 193px;
    display: flex;
    flex-direction: column;
}

.container--project-page {
    max-width: 1373px;
}

.project-page__first-section {
    display: flex;
    justify-content: space-between;
    gap: 42px;
    align-items: stretch;
}

.project-page__section {
    display: flex;
    justify-content: space-between;
    gap: 42px;
    align-items: center;
}

.project-page__section--contacts {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.project-page__item--custom-color {
    padding: 80px 0 80px 0;
    background-color: rgba(123, 134, 214, 0.06);
}

.project-page__item--white {
    padding: 72px 0 72px 0;
    background-color: white;
}

.project-page__item--development {
    padding: 72px 0 72px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 99px;
    background-color: rgba(123, 134, 214, 0.06);
}

.project-first-block__content {
    width: 33.4326%;
    display: flex;
    flex-direction: column;
}

.project-first-block {
    margin: 0 0 112px 0;
}

.project-page__title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 115.9%;
    letter-spacing: 0.04em;
    color: #242426;
}

.project-page__type {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 115.9%;
    letter-spacing: 0.04em;
    color: #242426;
    margin-top: 29px;
}

.project-first-block__bottom {
    margin-top: auto;
}

.project-first-block__bottom--mobile {
    display: none;
}

.project-page__heading {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
}

.project-page__text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    margin-top: 18px;
    list-style-position: outside;
    opacity: 0.7;
}

.project-page__text li {
    margin: 3px 0;
    //list-style-type: disc;
}

.project-page__front-image {
    width: 63.44%; 
}

.project-page__phone-image {
    width: 16%;
    //height: 441px; 
}

.project-page__desk-image {
    width: 684px;
    width: 50%;
   // height: 441px;
}

.project-page__footer-desk-image {
    width: 50%;
    //height: 562px;
}


.project-page__footer-phone-image {
    width: 16%;
    //height: 562px;
}

.project-page__content-box--small {
    max-width: 400px;
}

.project-page__content--list {
    padding-left: 20px;
}

.project-page__survey {
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.project-page__question {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    margin-top: 68px;
}

.application__contacts {
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 12;
    font-weight: 400;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: 0.04em;
}

.application__footer-link {
    text-align: right;
}

.container--project-page {
    position: relative;
    margin-right: auto;
    margin-left: auto;
}

.container--main-display {
    max-width: 1606px;
}

.application-form {
    padding-bottom: 0;
}

.project-page__independent-img {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
}

.project-page__iframe {
    min-height: 400px;
}

.project-page__video {
    width: 65%;
    height: 30vw;
    margin-left: auto;
    margin-right: auto;
}

@media (max-width: 1699px) {
    .container--project-page {
        max-width: 1300px;
    }
    
}

@media (max-width: 1499px) {
    .project-page {
        padding-top: 139px;
    }

    .container--project-page {
        max-width: 900px;
    }

    .project-page__section {
        gap: 24px;
    }

    .project-page__first-section {
        gap: 24px;
    }

    .project-page__item--development {
        gap: 87px;
    }

    .container--project-page {
        max-width: 1100px;
    }

    .project-page__title {
        font-size: 32px;
    }

    .project-page__type {
        margin-top: 19px;
        font-size: 22px;
    }

    .project-page__site-link {
        padding: 17px 38px 17px 36px;
    }

    .project-page__text {
        font-size: 16px;
        margin-top: 14px;
    }

    .project-page__question {
        margin-top: 60px;
    }

    .application__heading {
        font-size: 24px;
        margin-bottom: 12px;
    }

    .application__contacts {
        font-size: 24px;
    }

    .project-page__application {
        gap: 24px;
    }

    .application__input-container {
        gap: 24px;
    }
}

@media (max-width: 1199px) {
    .container--project-page {
        max-width: 833px;
    }

    .project-page__section {
        gap: 46px;
    }

    .project-page__first-section {
        flex-direction: column;
    }

    .container--project-page {
        flex-wrap: wrap;
    }

    .project-first-block__content {
        width: 100%;
    }

    .project-page__front-image {
        width: 100%;
    }

    .project-first-block__bottom {
        display: none;
    }

    .application__heading {
        display: none;
    }

    .application__heading--mobile {
        display: block;
    }
    

    .project-page__content-box {
        order: 2;
        width: 61%;
        padding: 0 35px;
    }

    .project-page__desk-image {
        order: 3;
        width: 100%;
    }

    .project-page__footer-desk-image {
        order: 3;
        width: 100%;
    }
    
    .project-page__footer-phone-image {
        order: 1;
        width: 33%;
    }

    .project-page__phone-image {
        order: 1;
        width: 33%;
    }

    .project-first-block__bottom--mobile {
        display: block;
        width: 100%;
    }

    .project-page__application {
        width: 390px;
    }

    .project-page__content-box--small {
        max-width: 100%;
        width: 100%;
    }

    .project-page__independent-img {
        width: 100%;
    }

    .project-page__video {
        width: 100%;
        height: 50vw;
    }
}

@media (max-width: 1199px) {
    .project-page__text {
        font-size: 18px;
    }
}



@media (max-width: 992px) {
    .container--project-page {
        max-width: none;
        padding: 0 80px;
    }

    .project-page__section {
        gap: 24px;
    }

}

@media (max-width: 767px) {
    .container--project-page {
        padding: 0 40px;
    }

    .project-page__section {
        flex-direction: column;
    }

    .project-page__item--development {
        gap:72px
    }

    .project-first-block {
        margin: 0 0 60px 0;
    }

    .project-page__type {
        margin-top: 16px;
    }

    .project-page__site-link {
        margin-top: 8px;
    }

    .project-page__site-link .services__consultation-btn-arrow {
        display: block;
        width: 29px;
    }

    .project-page__text {
        margin-top: 8px
    }

    .project-page__content-box {
        width: 100%;
    }

    .project-page__front-image {
        margin-top: 6px;
    }

    .project-page__desk-image {
        width: 90%;
    }

    .project-page__footer-desk-image {
        width: 90%;
    }

    .project-page__item--custom-color {
        padding: 36px 0 36px 0;
    }

    .project-page__item--development {
        padding: 36px 0 36px 0;
    }

    .project-page__item--white {
        padding: 60px 0 60px 0;
    }

    .project-page__question {
        margin-top: 40px;
        font-size: 18px;
    }

    .project-page__section--contacts {
        flex-direction: column;
    }

    .project-page__application {
        width: 100%;
        max-width: 525px;
        margin: auto;
    }

    .application__contacts {
        margin-top: 56px;
        width: 100%;
    }

    .application__footer-link {
        text-align: center;
    }

    .project-page__iframe {
        min-height: 350px;
    }
}

@media (max-width: 575px) {
    .project-page {
        padding-top: 69px;
    }

    .container--project-page {
        padding: 0 16px;
    }

    .project-page__heading {
        font-size: 18px;
    }

    .project-page__title {
        font-size: 24px;
    }

    .application__heading {
        margin-bottom: 0;
        font-size: 18px;
    }

    .project-page__type {
        font-size: 14px;
    }

    .project-page__text {
        font-size: 14px;
    }

    .project-page__iframe {
        min-height: 250px;
    }
}

@media (max-width: 410px) {
    .project-page__iframe {
        min-height: 200px;
    }
}
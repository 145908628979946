.bread-crumbs {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #999999;
    margin-bottom: 27px;
    width: 100%;
}

.bread-crumbs__link {
    text-decoration: underline;
}

@media (max-width: 1199px) {
    .bread-crumbs {
        margin-bottom: 24px;
    }
}

@media (max-width: 767px) {
    .bread-crumbs {
        margin-bottom: 18px;
        font-size: 12px;
    }
}
@import "../../mixins.scss";

.first-section-bg {
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    background: radial-gradient(69.81% 69.81% at 50% 26.71%, rgba(151, 123, 236, 0.88) 0%, rgba(151, 123, 236, 0.1) 100%), radial-gradient(86.23% 272.54% at 3.99% 15.65%, #584BEE 0%, #3A2DD1 100%);
    overflow: hidden;
}

.first-section-gradient {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
}

.first-section-gradient img {
    max-width: none;
}

.first-section-ball {
    position: absolute;
    right: -21px;
    top: -18px;
}

.first-section {
    padding-top: 481px;
    padding-bottom: 133px;
}

.first-section__heading {
    position: relative;
    z-index: 2;
    width: 1176px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 800;
    font-size: 84.5547px;
    line-height: 102.5%;
    letter-spacing: -0.01em;
    color: #fffbfa;
    margin-bottom: 67px;
}
.first-section__form {
}

@media (max-width: 1700px) {
    .first-section-ball {
        position: absolute;
        right: -32px;
        top: 125px;
        width: 1019px;
    }

    .first-section__heading {
        font-size: 76px;
    }
}

@media (max-width: 1500px) {
    .first-section {
        padding-top: 329px;
        padding-bottom: 121px;
    }

    .first-section-ball {
        position: absolute;
        right: -23px;
        top: 49px;
        width: 812px;
    }

    .first-section__heading {
        width: 889px;
        font-size: 63px;
    }
}

@media (max-width: 1440px) {
    .first-section-gradient {
        left: -20%;
    }
}

@media (max-width: 1200px) {
    .first-section {
        padding-top: 326px;
        padding-bottom: 104px;
    }

    .first-section-ball {
        position: absolute;
        right: -36px;
        top: 28px;
        width: 807px;
    }

    .first-section__heading {
        font-size: 57px;
    }
}

@media (max-width: 992px) {
    .first-section-gradient {
        left: 0;
    }

    .first-section-gradient img {
        width: 100%;
    }

    .first-section-ball {
        position: absolute;
        right: 25px;
        top: 57px;
        width: 582px;
    }

    .first-section {
        padding-top: 203px;
        padding-bottom: 82px;
    }

    .first-section__heading {
        max-width: 500px;
        font-size: 32px;
        margin-bottom: 34px;
    }


}

@media (max-width: 767px) {
    .first-section-ball {
        right: -25px;
        top: 45px;
        width: 537px;
    }
}

@media (max-width: 575px) {
    .first-section-gradient img {
        width: 100%;
    }

    .first-section-ball {
        display: none;
    }

    .first-section {
        padding-top: 129px;
        padding-bottom: 103px;
    }

    .first-section__heading {
        width: 324px;
        margin-right: auto;
        margin-left: auto;
        font-size: 28px;
        line-height: 102.5%;
        text-align: center;
        margin-bottom: 24px;
    }
}

@media (max-width: 359px) {

    .first-section {
        padding-top: 135px;
        padding-bottom: 66px;
    }

    .first-section__heading {
        width: 100%;
    }
}

.quiz {
    margin-top: 100px;
}

.quiz__text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 130%;
    color: #242426;
    margin-top: 24px;
    margin-bottom: 36px;
}

.quiz__slider {
    width: 1200px;
    max-width: 100%;
    margin-left: 0;
}

.quiz__slide {
    display: flex;
    flex-direction: column;
    padding: 30px 50px 30px 80px;
    border: 2px solid #242426;
    border-radius: 4px;
    height: auto;
}

.quiz__slide-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
}

.quiz__slide-progress {
    display: flex;
    align-items: center;
    width: 100%;
    
}

.quiz__slide-progress-numder {
    margin-right: 12px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    color: #75767E;
}

.quiz__slide-progress-bar {
    position: relative;
    width: 100%;
    height: 2px;
    background: #E3E3E3;
}

.quiz__slide-progress-stage {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    background-color: #604FE3;
}

.quiz__slide-title {
    margin-top: 35px;
    margin-bottom: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #242426;
}

.quiz__slide-questions {
    display: flex;
    flex-direction: column;
    gap: 21px;
}

.quiz__input-wrapper {
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #242426;
}

.quiz__slide-img {
    width: 370px;
    height: 370px;
    object-fit: contain;
}

.quiz__textarea {
    width: 110%;
    height: 193px;
    padding: 24px;
    border: 1px solid #999999;
    border-radius: 4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 130%;
    color: #242426;
}

.quiz__navigation {
    width: 1200px;
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding-bottom: 6px;
}

.quiz__navigation .swiper-button-disabled {
    opacity: 0;
}

@media (max-width: 1500px) {
    .quiz__slide-img {
        width: 255px;
        height: 255px;
    }
}

@media (max-width: 1200px) {
    .quiz__text {
        font-size: 24px;
    }
}

@media (max-width: 992px) {
    .quiz__text {
        font-size: 24px;
        margin-top: 12px;
        margin-bottom: 24px;
    }

    .quiz__slide {
        padding: 16px 34px 45px 26px;
    }

    .quiz__slide-progress-numder {
        font-size: 14px;
    }

    .quiz__slide-title {
        margin-top: 18px;
        font-size: 18px;
    }

    .quiz__slide-questions {
        gap: 18px;
    }

    .quiz__input-wrapper {
        font-size: 14px;
    }

    .quiz__slide-img {
        width: 225px;
        height: 225px;
    }

    .quiz__navigation {
        margin-top: 13px;
    }
}

@media (max-width: 767px) {
    .quiz__text {
        font-size: 14px;
    }

    .quiz__slide-title {
        font-size: 16px;
    }

    .quiz__slide-img {
        width: 155px;
        height: 155px;
    }
}

@media (max-width: 575px) {

    .quiz__textarea {
        width: 100%;
        height: 130px;
    }
    .quiz__slide-img {
        display: none;
    }
}
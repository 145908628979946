.faq {
}

.faq__container {
    display: flex;
    justify-content: space-between;
}
.faq__heading {
    max-width: 662px;
}
.faq__items {
    width: 726px;
    margin-top: 18px;
}
.faq__item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    border-bottom: 1px solid #e3e3e3;
    margin-bottom: 46px;
    cursor: pointer;
}
.faq__item:last-child {
    margin-bottom: 0;
}
.faq__item-inner {
    max-width: 659px;
    align-self: center;
    height: auto;
    overflow: hidden;
}

.faq__item-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    color: #242426;
    cursor: pointer;
}
.faq__item-answer {
}
.faq__text {
    display: none;
    padding-top: 12px;
    font-size: 16px;
    line-height: 140%;
    color: #242426;
}

.faq__item--active .faq__text {
    display: block;
}

.faq__item-icon {
    position: relative;
    width: 46px;
    height: 46px;
    cursor: pointer;
}

.faq__item-icon::after,
.faq__item-icon::before {
    content: "";
    position: absolute;
    width: 28px;
    height: 2px;
    top: 50%;
    left: 50%;
    background-color: #242426;
    transition: transform 0.25s ease-in;
}

.faq__item-icon::before {
    transform: translate(-50%, -50%);
}

.faq__item--active .faq__item-icon::before {
    transform: translate(-50%, -50%) rotate(45deg);
}

.faq__item-icon::after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.faq__item--active .faq__item-icon::after {
    transform: translate(-50%, -50%) rotate(135deg);
}

@media (max-width: 1700px) {
    .faq__heading {
        max-width: 631px;
    }
    .faq__item-inner {
        max-width: 618px;
    }
}

@media (max-width: 1500px) {
    .faq__container {
        flex-direction: column;
        justify-content: start;
    }

    .faq__heading {
        max-width: 100%;
        margin-bottom: 50px;
    }

    .faq__items {
        width: 100%;
        margin-top: 0;
    }

    .faq__item-inner {
        max-width: 90%;
    }
}

@media (max-width: 1200px) {
    .faq__item-title {
        font-size: 21px;
    }
}

@media (max-width: 992px) {
    .faq__heading {
        margin-bottom: 40px;
    }
    .faq__item {
        margin-bottom: 40px;
    }
    .faq__item-title {
        font-size: 18px;
    }
    .faq__item-icon::after,
    .faq__item-icon::before {
        width: 21px;
    }

    .faq__item-icon {
        width: 37px;
        height: 37px;
    }

    .faq__text {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
    .faq__heading {
        margin-bottom: 24px;
    }

    .faq__item-title {
        font-size: 14px;
        line-height: 152%;
    }

    .faq__item-icon::after,
    .faq__item-icon::before {
        width: 12px;
        height: 1px;
    }

    .faq__item-icon {
        width: 25px;
        height: 25px;
    }

    .faq__item {
        padding-bottom: 0;
        margin-bottom: 24px;
    }

    .faq__item-inner {
        margin-top: 3px;
    }

    .faq__item-answer {
        margin-bottom: 8px;
    }
}

@media (max-width: 359px) {

    .faq__item-title {
        line-height: 133%;
    }

    .faq__item--active .faq__item-title  {
        margin-bottom: 5px;
    }
}

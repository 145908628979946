.first-block {
    padding-top: 193px;
    overflow: hidden;
}

.first-block__container {
    display: flex;
    justify-content: space-between;
    gap: 42px;
    align-items: stretch;
}

.first-block__content {
    position: relative;
    width: 44%;
    display: flex;
    flex-direction: column;
}

.first-block__title {
    font-family: 'Mont';
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 110%;
    color: #242426;
}

.first-block__type {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #242426;
    margin-top: 24px;
    width: 80%;
}

.first-block__btn {
    margin-top: 36px;
}

.first-block__front-image-container {
    position: relative; 
    width: 36.3%;
    margin-right: 64px;
}

.first-block__front-image-container:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.first-block__front-image {
    width: 100%;
}

.first-block__front-image-container--mobile {
    display: none;
}

@media (max-width: 1700px) {
    .first-block__front-image-container {
        width: 40.0%;
        margin-right: 0;
    }

    .first-block__content {
        width: 52%;
    }
}

@media (max-width: 1499px) {
    

    .first-block {
        padding-top: 139px;
    }

    .first-block__front-image-container {
        width: 65.0%;
        margin-right: -50%;
    }

    .first-block__content {
        width: 70%;
    }
}

@media (max-width: 1200px) {
    .first-block__title {
        font-size: 32px;
        line-height: 115.9%;
    }

    .first-block__type {
        font-size: 14px;
    }

    .first-block__front-image-container {
        margin-top: 70px;
    }
}

@media (max-width: 575px) {
    .first-block {
        padding-top: 32px;
    }

    .first-block__container {
        flex-direction: column;
    }

    .first-block__title {
        font-size: 24px;
    }

    .first-block__content {
        width: 100%;
    }

    .first-block__type {
        width: 100%;
    }

    .first-block__front-image-container {
        display: none;
        width: 70%;
        margin: 32px auto -40px auto;
    }

    .first-block__front-image-container--mobile {
        display: block;
        z-index: -1;
    }
}
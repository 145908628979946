.thanks {
    height: 100vh;
    overflow: hidden;
}

.thanks__container {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
}

.thanks__heading {
    padding-bottom: 100px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    font-size: 160.919px;
    line-height: 80%;
    letter-spacing: -0.01em;
    color: #FFFBFA;
    z-index: 2;
}

.thanks-ball {
    position: absolute;
    right: -180px;
    z-index: 1;
}

.thanks__btn-container {
    gap: 48px;
    margin-top: 56px;
}

.thanks__btn-container--desk {
    display: flex;
}

.thanks__btn-container--tab, .thanks__btn-container--mob {
    display: none;
}

.thanks__btn {
    display: flex;
}

@media (max-width: 1500px) {

    .thanks__heading {
        font-size: 102px;
    }
}

@media (max-width: 992px) {

    .thanks-ball {
        width: 750px;
        right: 0%;
    }

    .thanks__heading {
        font-size: 72px;
    }

    .thanks__btn-container--desk {
        display: none;
    }

    .thanks__btn-container--tab {
        display: flex;
        gap: 18px;
    }
}

@media (max-width: 675px) {
    .thanks__container {
        justify-content: center;
    }

    .thanks-ball {
        width: 1750px;
        max-width: 1750px;
        right: -580px;
        top: -40%;
    }

    .thanks__btn-container--tab {
        display: none;
    }

    .thanks__btn-container--mob {
        display: flex;
        flex-direction: column;
        gap: 11px;
        margin-top: 32px;
    }
}

@media (max-width: 575px) {
    .thanks-ball {
        width: 1050px;
        max-width: 1050px;
        right: -420px;
        top: -30%;
    }

    .thanks__heading {
        font-size: 48px;
        padding-bottom: 0;
        padding-top: 100px;
    }
}


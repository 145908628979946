.services {
    position: relative;
    max-width: 1920px;
    margin-right: auto;
    margin-left: auto;
}
.services__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 61px;
}
.services__heading {
}

.services__categories {
    align-self: flex-end;
    display: flex;
    align-items: center;
}
.services__category-btn {
    padding: 8px 29px;
    font-size: 18px;
    margin-right: 10px;
}

.services__category-btn:last-child {
    margin-right: 0;
}

.services__items {
    margin-bottom: 58px;
}
.services__item {
    display: block;
    position: relative;
    transition: background 0.15s ease-in;
    overflow: hidden;
    cursor: pointer;
}

.services__item-gradient {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 50%;
    width: 1px;
    height: 1px;
    background-color: #8168f1;
    border-radius: 50%;
    box-shadow: 0px 0px 219px 632px #856afd;
    transition: opacity 0.15s ease-in;
}

.services__item:hover .services__item-gradient {
    opacity: 1;
}

.services__item:hover {
    background: #604fe3;
}


.services__item-inner {
    display: flex;
    align-items: center;
    padding-top: 27px;
    padding-bottom: 27px;
    border-bottom: 2px solid #e3e3e3;
    transition: border-bottom 0.15s ease-in;
}

.services__item:hover .services__item-inner {
    border-bottom: 2px solid #604fe3;
}

.services__left-wrapper {
    flex-shrink: 0;
    width: 585px;
    margin-right: 121px;
}

.services__item-title {
    display: block;
    font-size: 24px;
    line-height: 140%;
    color: #242426;
    transition: color 0.15s ease-in;
}
.services__item:hover .services__item-title {
    color: white;
}

.services__item-price {
    display: block;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    transition: color 0.15s ease-in;
}
.services__item:hover .services__item-price {
    color: white;
}

.services__item-arrow {
    margin-right: 304px;
    transition: color 0.15s ease-in;
}

.services__item:hover .services__item-arrow {
    color: white;
}

.services__description {
    width: 382px;
    font-size: 18px;
    line-height: 140%;
    color: #242426;
    text-align: right;
    transition: color 0.15s ease-in;
}

.services__item:hover .services__description {
    color: white;
}

.services__consultation-btn {
    display: inline-block;
    padding: 21px 79px;
    font-size: 18px;
}

.services__consultation-btn-arrow {
    margin-left: 24px;
}

.services__btn {
    max-width: 586px;
}

@media (max-width: 1700px) {

    .services__left-wrapper {
        width: 493px;
        margin-right: 76px;
    }

    .services__description {
        flex-shrink: 0;
    }

    .services__item-arrow {
        margin-right: 133px;
    }
}

@media (max-width: 1500px) {
    .services__item-arrow {
        display: none;
    }

    .services__category-btn {
        padding: 8px 11px;
        font-size: 17px;
    }

    .services__left-wrapper {
        width: 441px;
    }
}

@media (max-width: 1200px) {
    .services__header {
        flex-direction: column;
        margin-bottom: 29px;
    }

    .services__categories {
        align-self: flex-start;
    }

    .services__category-btn {
        padding: 8px 53px;
    }

    .services__item-title {
        font-size: 21px;
    }

    .services__item-price {
        font-size: 21px;
    }

    .services__description {
        font-size: 17px;
    }

    .services__left-wrapper {
        width: 390px;
    } 
    .services__description {
        width: 367px;
    }
}

@media (max-width: 992px) {
    .services__header {
        margin-bottom: 24px;
    }

    .services__heading {
        margin-bottom: 36px;
    }

    .services__categories {
        align-self: flex-start;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .services__category-btn {
        width: 25%;
        padding: 7px 26px;
        font-size: 16px;
        margin-right: 12px;
    }

    .services__item-gradient {
        box-shadow: 0px 0px 81px 305px #856afd;
    }

    .services__item-inner {
        justify-content: space-between;
        padding-top: 25px;
        padding-bottom: 25px;
    }

    .services__item-title {
        font-size: 18px;
    }

    .services__item-price {
        font-size: 18px;
    }

    .services__description {
        max-width: 293px;
        font-size: 14px;
    }

    .services__items {
        margin-bottom: 36px;
    }

    .services__consultation-btn {
        padding: 18px 51px;
        font-size: 16px;
    }

    .services__consultation-btn-arrow {
        margin-left: 20px;
    }

    .services__description {
        max-width: 293px;
    }

    .services__left-wrapper {
        max-width: 293px;
        margin-right: 0;
    }

    .services__btn {
        max-width: 100%;
    }
}

@media (max-width: 767px) {
    .services__category-btn {
        width: 25%;
        padding: 7px 16px;
        font-size: 15px;
        margin-right: 12px;
    }
    .services__item-title {
        font-size: 15px;
    }

    .services__item-price {
        font-size: 15px;
    }

    .services__description {
        max-width: 235px;
        font-size: 13px;
    }

    .services__consultation-btn {
        padding: 16px 34px;
        font-size: 15px;
    }

    .services__left-wrapper {
        max-width: 258px;
    }
}

@media (max-width: 575px) {
    .services__header {
        margin-bottom: 6px;
    }

    .services__heading {
        margin-bottom: 24px;
    }

    .services__categories {
        width: 100%;
        overflow: scroll;
        padding-bottom: 10px;
     
    }

    .services__category-btn {
        flex-shrink: 0;
        width: max-content;
        padding: 6px 21px;
        font-size: 14px;
    }

    .services__item-inner {
        flex-direction: column;
        justify-content: flex-start;
        align-items:flex-start;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .services__left-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        max-width: none;
        margin-bottom: 26px;
    }

    .services__item-title {
        max-width: 56%;
        font-size: 20px;
    }

    .services__item-price {
        font-size: 16px;
    }

    .services__description {
        max-width: 56%;
        text-align: left;
    }

    .services__items {
        margin-bottom: 36px;
    }

    .services__consultation-btn {
        justify-content: center;
        width: 100%;
        padding: 9.5px 19px;
        font-size: 14px;
    }

    .services__consultation-btn-arrow {
        display: none;
    }

    .services__item-gradient {
        box-shadow: 0px 0px 60px 165px #856afd;
    }
}

@media (max-width: 359px) {


    .services__left-wrapper {
        margin-bottom: 20px;
    }
    .services__category-btn {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .services__item-title {
        max-width: 60%;
        font-size: 17px;
    }

    .services__description {
        max-width: 60%;
    }

    .services__consultation-btn {
        padding: 9px 3px;
        font-size: 13px;
    }
}

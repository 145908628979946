@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-ExtraLight.woff2') format('woff2'),
        url('./Montserrat-ExtraLight.woff') format('woff'),
        url('./Montserrat-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-MediumItalic.woff2') format('woff2'),
        url('./Montserrat-MediumItalic.woff') format('woff'),
        url('./Montserrat-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Italic.woff2') format('woff2'),
        url('./Montserrat-Italic.woff') format('woff'),
        url('./Montserrat-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-ExtraBoldItalic.woff2') format('woff2'),
        url('./Montserrat-ExtraBoldItalic.woff') format('woff'),
        url('./Montserrat-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-LightItalic.woff2') format('woff2'),
        url('./Montserrat-LightItalic.woff') format('woff'),
        url('./Montserrat-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Medium.woff2') format('woff2'),
        url('./Montserrat-Medium.woff') format('woff'),
        url('./Montserrat-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Light.woff2') format('woff2'),
        url('./Montserrat-Light.woff') format('woff'),
        url('./Montserrat-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-SemiBold.woff2') format('woff2'),
        url('./Montserrat-SemiBold.woff') format('woff'),
        url('./Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Black.woff2') format('woff2'),
        url('./Montserrat-Black.woff') format('woff'),
        url('./Montserrat-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-ThinItalic.woff2') format('woff2'),
        url('./Montserrat-ThinItalic.woff') format('woff'),
        url('./Montserrat-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Regular.woff2') format('woff2'),
        url('./Montserrat-Regular.woff') format('woff'),
        url('./Montserrat-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Bold.woff2') format('woff2'),
        url('./Montserrat-Bold.woff') format('woff'),
        url('./Montserrat-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-ExtraLightItalic.woff2') format('woff2'),
        url('./Montserrat-ExtraLightItalic.woff') format('woff'),
        url('./Montserrat-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-BoldItalic.woff2') format('woff2'),
        url('./Montserrat-BoldItalic.woff') format('woff'),
        url('./Montserrat-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-BlackItalic.woff2') format('woff2'),
        url('./Montserrat-BlackItalic.woff') format('woff'),
        url('./Montserrat-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-SemiBoldItalic.woff2') format('woff2'),
        url('./Montserrat-SemiBoldItalic.woff') format('woff'),
        url('./Montserrat-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-ExtraBold.woff2') format('woff2'),
        url('./Montserrat-ExtraBold.woff') format('woff'),
        url('./Montserrat-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Montserrat-Thin.woff2') format('woff2'),
        url('./Montserrat-Thin.woff') format('woff'),
        url('./Montserrat-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}


.how-we-work {
    margin-top: 100px;
}

.how-we-work__container {
    display: flex;
    justify-content: space-between;
}

.how-we-work__heading {
    width: 21.4%;
}

.how-we-work__heading-service {
}

.how-we-work__content {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 74.7%;
}

.how-we-work__item {
    position: relative;
    width: 50%;
    padding: 137px 35px 100px 35px;
    color: #242426;
    transition: 0.4s;
    overflow: hidden;
}

.how-we-work__item:nth-child(1) {
    border: 2px solid #75767e;
}

.how-we-work__item:nth-child(2) {
    border-right: 2px solid #75767e;
    border-top: 2px solid #75767e;
    border-bottom: 2px solid #75767e;
}

.how-we-work__item:nth-child(3) {
    border-left: 2px solid #75767e;
    border-bottom: 2px solid #75767e;
    border-right: 2px solid #75767e;
}

.how-we-work__item:nth-child(4) {
    border-bottom: 2px solid #75767e;
    border-right: 2px solid #75767e;
}

.dev-stage__number {
    position: absolute;
    top: 41px;
    left: 35px;
    font-weight: 600;
    font-size: 32px;
    line-height: 140%;
    z-index: 2;
}

.dev-stage__number--mobile {
    display: none;
    position: relative;
}

.dev-stage__heading {
    font-weight: 600;
    font-size: 32px;
    line-height: 127%;
    position: relative;
}

.dev-stage__text {
    margin-top: 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    position: relative;
}

.survey__container {
    display: flex;
    gap: 21px;
    margin-top: 21px;
}

.survey__label {
    cursor: pointer;
}

.survey__img {
    width: 56.6px;
}

.survey__input {
    display: none;
}

.project-page__application {
    position: relative;
    width: 504px;
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.application__policy {
    display: block;
    font-weight: 300;
    font-size: 14px;
    line-height: 128.91%;
    color: #242426;
    margin-top: 14px;
}

.application__policy--link {
    text-decoration: underline;
}

.application__heading {
    font-weight: 500;
    font-size: 32px;
    line-height: 110%;
}

.application__heading--mobile {
    display: none;
}

.application__input-container {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.application__input {
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 2px solid #242426;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
}

.application__consultation-btn {
    width: 100%;
    padding: 21px;
    justify-content: center;
    font-size: 18px;
}

.consultation-btn {
    width: 100%;
}

.how-we-work__button {
    margin-top: 36px;
}

@media (max-width: 1700px) {
    .how-we-work__content {
        width: 69%;
    }

    .how-we-work__heading {
        width: 26.4%;
    }
}

@media (max-width: 1699px) {
    .how-we-work__item {
        padding: 110px 35px 100px 35px;
    }

    .dev-stage__number {
        top: 32px;
    }
}

@media (max-width: 1500px) {
    .how-we-work__container {
        flex-direction: column;
    }

    .how-we-work__content {
        width: 100%;
    }

    .how-we-work__heading {
        width: 100%;
        margin-bottom: 51px;
    }

    .how-we-work__heading-service {
        margin-bottom: 36px;
    }

    .dev-stage__number {
        font-size: 24px;
    }

    .dev-stage__heading {
        font-size: 24px;
    }

    .dev-stage__text {
        font-size: 16px;
    }
}

@media (max-width: 1200px) {
    .how-we-work__item {
        padding: 74px 18px 54px 18px;
    }

    .dev-stage__number {
        top: 21px;
        left: 18px;
    }

    .consultation-btn {
        font-size: 14px;
        padding: 18px;
    }

    .application__input {
        font-size: 18px;
    }

    .survey__container {
        gap: 18px;
    }

    .survey__img {
        width: 50px;
    }
}

@media (max-width: 992px) {
    .application__policy {
        font-size: 12px;
    }

    .dev-stage__text {
        margin-top: 4px;
    }
}

@media (max-width: 768px) {
    .dev-stage__number {
        font-size: 18px;
    }

    .dev-stage__heading {
        font-size: 18px;
    }

    .dev-stage__text {
        font-size: 14px;
    }

    .application__input {
        font-size: 14px;
    }
}

@media (max-width: 575px) {
    
    .how-we-work__item:nth-child(1), 
    .how-we-work__item:nth-child(2), 
    .how-we-work__item:nth-child(3), 
    .how-we-work__item:nth-child(4) {
        border: none;
        border-bottom: 0.374791px solid #e3e3e3;
    }

    .how-we-work__heading-service {
        margin-bottom: 24px;
    }

    .how-we-work__item {
        padding: 31px 21px 31px 40px;
        width: 100%;
        border: none;
    }

    .how-we-work__item:first-child {
        border-top: 0.374791px solid #e3e3e3;
    }

    .how-we-work__button--service {
        margin-top: 18px;
    }

    .dev-stage__text {
        margin-top: 12px;
    }

    .dev-stage__number {
        top: 31px;
    }

    .dev-stage--service .dev-stage__number {
        font-size: 16px;
    }

    .dev-stage--service .dev-stage__heading {
        font-size: 16px;
    }

    .consultation-btn {
        padding: 9.5px;
    }

    .consultation-btn .services__consultation-btn-arrow {
        display: none;
    }

    .survey__container {
        gap: 12px;
    }

    .survey__img {
        width: 31.3px;
    }

    .dev-stage--service {
        padding-left: 22px;
        padding-bottom: 12px;
        padding-top: 20px;
        border-top: none;
        border-bottom: 1px solid transparent;
        border-image: linear-gradient(90deg,rgba(51,51,51,0),rgba(51,51,51,.5) 58%,rgba(51,51,51,0));
        border-image-slice: 1;
    }

    .dev-stage--service:first-child {
        padding-top: 0;
        border-top: none;
    }

    .dev-stage--service .dev-stage__number{
        left: 3px;
        top: 20px;

    }

        

    
    .dev-stage--service:first-child  .dev-stage__number {
        top: 0;
    }
}
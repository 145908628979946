.btn {
    display: flex;
    align-items: center;
    border: 2px solid #604fe3;
    border-radius: 4px;
    font-weight: 600;
    line-height: 1.2;
    color: #604fe3;
    cursor: pointer;
    transition: 0.25s ease-in;
}

.btn:hover {
    background: #604fe3;
    color: #ffffff;
}


.btn--tab {
    border: 2px solid #242426;
    color: #242426;
}

.btn--tab:hover {
    border: 2px solid #A99DFF;
    color: #A99DFF;
    background-color: white;
}

.btn--tab-active {
    border: 2px solid #604fe3;
    background: #604fe3;
    color: #ffffff;
}

.btn--tab-active:hover {
    border: 2px solid #604fe3;
    background: #604fe3;
    color: #ffffff;
}

.btn--64 {
    height: 64px;
    padding: 0 21px;
    justify-content: center;
    height: 64px;
    width: 100%;
    font-size: 18px;
    background: #604FE3;
    color: white;
}

.btn--link {
    width: fit-content;
    margin-top: 18px;
    padding: 0 40px;
    font-size: 18px;
    justify-content: center;
    height: 64px;
}

.btn--purple {
    border: 2px solid #A99DFF;
    background: #A99DFF;
    color: #ffffff;
}

.btn--purple:hover {
    border: 2px solid #A99DFF;
    background: #604FE3;
    color: #FFFFFF;
}

.btn--blue {
    background: #604FE3;
    border: 2px solid #604FE3;
    color: #ffffff;
}

.btn--blue:hover {
    background: #A99DFF;
    border: 2px solid #A99DFF;
    color: #ffffff;
}

.radio-button {
    position: relative;
    width: 28px;
    height: 28px;
    appearance: none;
    margin-right: 15px;
    border: 2px solid #75767E;
    border-radius: 50%;
}

.radio-button:checked {
    border: 2px solid #604FE3;
}

.radio-button:checked::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-52%, -54%);
    content: "";
    width: 12px;
    height: 12px;
    background-color: #604FE3;
    border-radius: 50%;
}

.checkbox-button {
    position: relative;
    width: 28px;
    height: 28px;
    appearance: none;
    margin-right: 15px;
    border: 2px solid #75767E;
    border-radius: 8px
}

.checkbox-button:checked {
    border: 2px solid #604FE3;
}

.checkbox-button:checked::before {
    position: absolute;
    top: 40%;
    left: 55%;
    transform: translate(-60%, -40%) rotate(-45deg);
    content: "";
    width: 12px;
    height: 7px;
    border-bottom: 3px solid #604FE3;
    border-left: 3px solid #604FE3;
}

@media (max-width: 992px) {
    .btn--64 {
        font-size: 16px;
    }

    .btn--link {
        padding: 0 32px;
        font-size: 16px;
        margin-top: 13px;
    }

    .radio-button {
        width: 18px;
        height: 18px;
        margin-right: 13px;
    }

    .radio-button:checked::before {
        width: 7px;
        height: 7px;
    }

    .checkbox-button {
        width: 18px;
        height: 18px;
        border-radius: 4px;
        margin-right: 13px;
    }

    .checkbox-button:checked::before {
        width: 8px;
        height: 5px;
    }
}

@media (max-width: 767px) {
    .btn--link {
        height: 46px;
    }
}

@media (max-width: 575px) {
    .btn--64 {
        font-size: 14px;
        padding: 0;
    }

    .btn--link {
        font-size: 14px;
        margin-top: 9px;
    }
}

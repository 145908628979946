@import 'mixins.scss';
body {
  
}

.inner-img{
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
}

// .contacts h3 {
//     @include adaptive-font(22, 19);
// }
.works {
    overflow: hidden;
}
.works__heading {
    margin-bottom: 51px;
}

.works__heading-service {
    margin-bottom: 36px;

}

.works__slider {
    width: 1761px;
    margin-bottom: 22px;
}

.works__slide {
    width: 707px !important;
}

.works__slide-inner {
    width: 100%;
}
.works__img-wrapper {
    display: block;
    width: 100%;
    height: 403px;
    border-radius: 9px;
    overflow: hidden;
    border: 2px solid #e3e3e3;
    margin-bottom: 16px;
    transition: border .25s ease-in;
}

.works__slide:hover .works__img-wrapper {
    border: 2px solid #604FE3;
}

.works__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.works__slide-title-wrapper {
    display: flex;
    align-items: center;
}

.works__slide-title {
    flex-shrink: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: #242426;
    margin-right: 40px;
    transition: color 0.25s ease-in;
}

.works__slide:hover .works__slide-title {
    color: #604fe3;
}

.works__slide-arrow {
    flex-grow: 1;
    position: relative;
    display: block;
    height: 2px;
    background-color: #242426;
    transition: background-color 0.25s ease-in;
}

.works__slide:hover .works__slide-arrow {
    background-color: #604fe3;
}

.works__slide-arrow::after {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    top: -4px;
    right: -3px;
    background-color: #242426;
    transform: rotate(45deg);
    transition: background-color 0.25s ease-in;
}

.works__slide:hover .works__slide-arrow::after {
    background-color: #604fe3;
}

.works__slide-arrow::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 2px;
    top: 3px;
    right: -3px;
    background-color: #242426;
    transform: rotate(-45deg);
    transition: background-color 0.25s ease-in;
}

.works__slide:hover .works__slide-arrow::before {
    background-color: #604fe3;
}

.works__navigation {
    display: flex;
    align-items: center;
}

.works__slider-bullets-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}
.works__pagination-bullet {
    width: 6px;
    height: 6px;
    background-color: #e3e3e3;
    margin-right: 13px;
    transition: background-color 0.25s ease-in;
}
.works__pagination-bullet--active {
    background-color: #604fe3;
}

.works__slider-arrow {
    position: relative;
    width: 34px;
    height: 34px;
    border: 2px solid #e3e3e3;
    border-radius: 4px;
    transition: border 0.25s ease-in;
    cursor: pointer;
}

.works__slider-arrow:hover {
    border: 2px solid #604fe3;
}

.works__slider-arrow::before {
    content: "";
    position: absolute;
    width: 7px;
    height: 7px;
    border-top: 2px solid #242426;
    border-right: 2px solid #242426;
}

.works__slider-arrow-next {
    margin-right: 18px;
}

.works__slider-arrow-next::before {
    right: 39%;
    top: 39%;
    transform: rotate(45deg);
}
.works__slider-arrow-prev {
    margin-right: 6px;
}

.works__slider-arrow-prev::before {
    right: 31%;
    top: 39%;
    transform: rotate(-135deg);
}




@media (max-width: 1700px) {

    .works__slide {
        width: 608px !important;
    }

    .works__img-wrapper {
        height: 359px;
    }
}


@media (max-width: 1200px) {
    .works__slide {
        width: 583px !important;
    }

    .works__img-wrapper {
        height: 331px;
    }

    .works__slide-title {
        font-size: 22px;
    }
}

@media (max-width: 992px) {
    .works__slider {
        width: calc(100vw - 80px);
        margin-bottom: 16px;
    }

    .works__heading {
        margin-bottom: 36px;
    }

    .works__slide {
        width: 610px !important;
    }

    .works__img-wrapper {
        height: 388px;
        margin-bottom: 16px;
    }

    .works__slide-title {
        font-size: 18px;
    }

    .works__slide-arrow {
        display: none;
    }
}

@media (max-width: 767px) {
    .works__slider {
        width: calc(100vw - 40px);
    }

    .works__slide {
        width: 493px !important;
    }

    .works__img-wrapper {
        height: 290px;
    }
}

@media (max-width: 575px) {
    .works__heading-service {
        margin-bottom: 24px;
    
    }
    .works__slider {
        width: calc(100vw - 16px);
        margin-bottom: 14px;
    }

    .works__heading {
        margin-bottom: 24px;
    }

    .works__slide {
        width: 300px !important;
    }

    .works__img-wrapper {
        height: 199px;
        margin-bottom: 8px;
    }

    .works__slide-title {
        font-size: 14px;
    }
}

@media (max-width: 359px) {
    .works__slide {
        width: 269px !important;
    }
    .works__img-wrapper {
        height: 150px;
    }
}

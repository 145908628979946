.modal-thanks {
    width: 431px;
    height: 616px;
    padding: 183px 60px 60px 60px;
    background: #ffffff;
    border-radius: 6px;
}
.modal-thanks__text {
    width: 238px;
    margin-right: auto;
    margin-left: auto;
    font-weight: 700;
    font-size: 32px;
    line-height: 125%;
    text-align: center;
    color: #2b2a39;
}

@media (max-width: 575px) {
    .modal-thanks {
        width: 100%;
        padding: 274px 16px 16px 16px;
        border-radius: 4px;
    }

    .modal-thanks__text {
        width: 100%;
        font-size: 28px;
        line-height: 121%;
    }
}

@media (max-width: 359px) {
}

.modal-order {
    width: 431px;
    padding: 59px 52px 52px 52px;
    background-color: #fff;
    border-radius: 6px;
}
.modal-order__heading {
    font-weight: 700;
    font-size: 36px;
    line-height: 122%;
    text-align: center;
    color: #2b2a39;
    margin-bottom: 47px;
}
.modal-order__img {
    display: block;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;
}

.modal-order__text {
    width: 322px;
    margin-right: auto;
    margin-left: auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    color: #242426;
    margin-bottom: 31px;
}

.modal-order__heading--small {
    font-size: 30px;
}

.modal-order__text--left {
    text-align: left;
}

.modal-order__inputs {
    margin-bottom: 24px;
}
.modal-order__input {
    width: 100%;
    padding-bottom: 8px;
    border-bottom: 1px solid  #242426;
    margin-bottom: 18px;
    font-size: 16px;
    color: #2b2a39;
}

.modal-order__input::placeholder {
    color: #999999;
}

.modal-order__input:last-child {
    margin-bottom: 0;
}

.modal-order__btn {
    margin-bottom: 8px;
}

.modal-order__policy {
    width: 100%;
    font-weight: 300;
    font-size: 12px;
    line-height: 128.91%;
    color: #242426;
}
.modal-order__policy-link {
    text-decoration: underline;
}


@media (max-width: 575px) {
    .modal-order__heading {
        font-size: 36px;
    }

    .modal-order {
        width: 100%;
        padding: 86px 16px 45px;
        border-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .modal-order__heading {
        font-size: 28px;
        margin-bottom: 46px;
    }

    .modal-order__img {
        width: 129px;
        margin-bottom: 46px;
    }

    .modal-order__text {
        font-size: 16px;
        margin-bottom: 24px;
    }

    .modal-order__input {
        font-size: 12px;
        margin-bottom: 12px;
    }

    .modal-order__inputs{
        margin-bottom: 24px;
    }
  
}
@media (max-height: 612px) {
    .modal-order {
        border-radius: 0;
    }
}

@media (max-width: 359px) {

    .modal-order__text {
        width: 100%;
    }
  
}
@import '../mixins.scss';

.custom-input {
    padding: 24px 23px;
    padding: 4.7% 4.5%;
    border: 2px solid #242426;
    font-size: 24px;
    @include adaptive-font(24, 16);
    line-height: 1.2;
    color: #242426;
    min-height: 49px;
}

.custom-input::placeholder {
    color: #242426;
}
.section-heading {
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    color: #242426;
}

@media (max-width: 1200px) {
    .section-heading {
        font-size: 50px;
        margin-bottom: 44px;
    }
}
@media (max-width: 992px) {
    .section-heading {
        font-size: 32px;
    }
}

@media (max-width: 575px) {
    .section-heading {
        font-size: 24px;
    }
}

.main-form {
    overflow: hidden;
}

.main-form__heading {
    margin-bottom: 0;
}

.main-form__application {
    position: relative;
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: 48px;
    max-width: 800px;
}

.main-form__section--contacts {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.main-form__link {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: #242426;
    text-align: right;
    
}

.main-form__circles {
    position: absolute;
    left: 57%;
}

.main-form__circles--mob {
    display: none;
    position: absolute;
}

@media (max-width: 1699px) {
    .main-form__circles {
        left: 72%;
    }
}

@media (max-width: 1499px) {
    .main-form__application {
        gap: 36px;
        margin-right: 24%;
    }

    .main-form__circles {
        display: none;
    }

    .main-form__circles--mob {
        display: block;
        right: -70%;
        height: 100%;
    }
}

@media (max-width: 1200px) {
    .main-form__link {
        font-size: 21px;
    }

    .main-form__circles--mob {
        right: -59%;
    }
}

@media (max-width: 992px) {
    .main-form__link {
        font-size: 18px;
    }
}

@media (max-width: 767px) {
    .main-form__section--contacts {
        flex-direction: column;
        align-items: flex-start;
    }
    .main-form__link {
        text-align: left;
    }

    .main-form .application__contacts {
        margin-top: 40px;
    }
}

@media (max-width: 686px) {
    .main-form__application {
        margin-right: 0;
    }

    .main-form__circles--mob {
        display: none;
    }
}

@media (max-width: 575px) {
    .main-form__application {
        width: 100%;
        max-width: 370px;
    }

    .main-form__link {
        font-size: 14px;
    }


}
.conditions {
   
}
.conditions__heading {
    margin-bottom: 36px;
}

.conditions__items {
    position: relative;
    display: flex;
    flex-direction: column;
}

.conditions__item {
    flex-shrink: 0;
    display: flex;
    margin-bottom: 161px;
}
.conditions__item:last-of-type {
    margin-bottom: 0;
}



.conditions__img-wrapper {
    align-self: flex-start;
    position: relative;
    margin-right: 36px;
}
.conditions__img {
}

.conditions__text-wrapper {
    align-self: flex-end;
    max-width: 381px;
    padding-bottom: 22px;
}
.conditions__item-title {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    color: #242426;
    margin-bottom: 4px;
}
.conditions__item-description {
    font-size: 18px;
    line-height: 140%;
    color: #242426;
}

.conditions__item:nth-child(2) {
    position: absolute;
    right: 0;
    top: 305px;

}

.conditions__item:nth-child(2n) .conditions__img-wrapper {
    order: 2;
    margin-right: 0;
    margin-bottom: 0;
}

.conditions__item:nth-child(2n) .conditions__text-wrapper {
    order: 1;
    margin-right: 36px;
}



@media (max-width: 1700px) {

    .conditions__item {
        margin-bottom: 95px;
    }

    .conditions__item:nth-child(2n) {
        position: static;
        align-self: flex-end;
    }

}

@media (max-width: 1500px) {

    .conditions__img {
        height: auto;
        width: 320px;
    }

    .conditions__item {

    }

    .conditions__item-title {
        font-size: 22px;
    }

    .conditions__item-description {
        font-size: 17px;
    }

    .conditions__text-wrapper {
        padding-bottom: 51px;
    }
}

@media (max-width: 1200px) {

    .conditions__items {
        width: 100%;
        height: auto;
        flex-direction: column;
        overflow-x: unset;
    }

    .conditions__item {
        margin-right: 0;
        margin-bottom: 135px;
    }

    .conditions__item:last-of-type {
        margin-bottom: 37px;
    }

    .conditions__item-title {
        font-size: 20px;
    }

    .conditions__img-wrapper {
        margin-right: 24px;
    }

    .conditions__img {
        height: auto;
        width: 320px;
    }

    .conditions__item-description {
        font-size: 16px;
    }

    .conditions__text-wrapper {
        max-width: 333px;
        padding-bottom: 15px;
    }

    .conditions__item:nth-child(2n) .conditions__img-wrapper {
        margin-right: 0;
        margin-left: 24px;
    }

}

@media (max-width: 992px) {
    .conditions__item {
        margin-bottom: 123px;
    }

    .conditions__img {
        width: 208px;
    }

    .conditions__text-wrapper {
        max-width: 293px;
    }

    .conditions__item-title {
        font-size: 18px;
        margin-bottom: 8px;
    }

    .conditions__item-description {
        font-size: 14px;
    }
}

@media (max-width: 767px) {
    .conditions__item {
        margin-bottom: 105px;
    }

    .conditions__img {
        width: 179px;
    }

    .conditions__item-title {
        font-size: 17px;
    }
}

@media (max-width: 575px) {
    .conditions__heading {
        margin-bottom: 24px;
    }

    .conditions__item {
        flex-direction: column;
        align-items: center;
        margin-bottom: 54px;
    }

    .conditions__item:last-of-type {
        margin-bottom: 3px;
    }

    .conditions__img-wrapper {
        margin-right: 0;
        margin-bottom: 36px;
        align-self:unset;
    }

    .conditions__img {
        width: 142px;
    }

    .conditions__text-wrapper {
        max-width: 293px;
        align-self: unset;
        padding-bottom: 0;
    }

    .conditions__item-title {
        font-size: 16px;
        text-align: center;
    }

    .conditions__item-description {
        text-align: center;
    }

    .conditions__item:nth-child(2n) {
        align-self: unset;
    }

    .conditions__item:nth-child(2n) .conditions__img-wrapper {
        order: unset;
        margin-left: 0;
        margin-bottom: 36px;
    }

    .conditions__item:nth-child(2n) .conditions__text-wrapper {
        order:unset;
        margin-right: 0;
    }
    
}

@media (max-width: 359px) {
}

.hor-form {
    display: flex;
}

.hor-form__inner {
    margin-right: 20px;
}

.hor-form__inputs {
    display: flex;
    margin-bottom: 12px;
}

.hor-form__input {
    font-size: 20px;
    line-height: 1.2;
    color: white;
    border-bottom: 1px solid white;
    padding-bottom: 12px;
    margin-right: 36px;
}

.hor-form__input:last-child {
    margin-right: 0;
}

.hor-form__input::placeholder {
    color: white;
}

.hor-form__input-name {
    width: 188px;
}

.hor-form__input-phone {
    width: 220px;
}

.hor-form__consent {
    font-size: 12px;
    line-height: 123.3%;
    color: white;
}

.hor-form__consent-link {
    margin-left: 3px;
    text-decoration: underline;
}

.hor-form__consent-link:hover {
    text-decoration: underline;
}

.hor-form__submit {
    font-size: 18px;
    padding: 19px 45px;
}

.hor-form__consent--mobile {
    display: none;
}

@media (max-width: 1200px) {
    .hor-form__input {
        font-size: 18px;
        padding-bottom: 8px;
    }

    .hor-form__submit {
        padding: 16.5px 29px;
        font-size: 17px;
    }
}

@media (max-width: 992px) {
    .hor-form {
        flex-direction: column;
        width: 260px;
       
    }

    .hor-form__inner {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .hor-form__inputs {
        flex-direction: column;
        margin-bottom: 5px;
    }

    .hor-form__input {
        font-size: 14px;
        padding-bottom: 5px;
        margin-bottom: 14px;
    }

    .hor-form__input:last-child {
        margin-bottom: 0;
    }
    
    .hor-form__input {
        margin-right: 0;
    }

    .hor-form__input-name {
        width: 100%;
    }
    
    .hor-form__input-phone {
        width: 100%;
    }

    .hor-form__submit {
        font-size: 14px;
        line-height: 20px;
        padding: 11px 63px;
    }
}


@media (max-width: 767px) {
 
}

@media (max-width: 575px) {

    .hor-form {
        width: 260px;
        margin-right: auto;
        margin-left: auto;
    }

    .hor-form__inner {
        margin-bottom: 26px;
    }
    .hor-form__inputs {
        margin-bottom: 0;
    }

    .hor-form__input {
        font-size: 16px;
        margin-bottom: 0;
        margin-right: 17px;
    }

    .hor-form__input::placeholder {
        color: rgba($color: #fff, $alpha: 0.6);
    }

    .hor-form__input:last-child {
        margin-right: 0;
    }

    .hor-form__input-name {
        width: 100%;
        margin-bottom: 19px;
    }
    
    .hor-form__input-phone {
        width: 100%;
    }

    .hor-form__submit {
        font-size: 14px;
        line-height: 17px;
        padding: 16px 63px;
        margin-bottom: 19px;
    }

    .hor-form__consent {
        display: none;
    }

    .hor-form__consent--mobile {
        display: block;
        color: rgba($color: #fff, $alpha: 0.7);
    }

}

@media (max-width: 359px) {
 
    
  
}



.footer { 
    display: flex;
    gap: 26px;
    margin: 100px auto 0 auto;
    justify-content: space-between;
    align-items: center;
    padding-top: 58px;
    padding-bottom: 58px;
    margin-right: auto;
    margin-left: auto;
    border-top: 1px solid #999999;
    flex-wrap: wrap;
}

.footer__container {
    display: flex;
    flex-direction: column; 
    gap: 5px;
}

.footer__item {
    font-size: 16px;   
    font-weight: 500;
}

.footer__min-logo {
    display: none;
}

.footer__container--policy {
    padding-bottom: 20px;
}

@media (max-width: 1499px) {
    .footer { 
        margin: 100px auto 0 auto;
        padding-top: 26px;
        padding-bottom: 26px;
    }

    .footer__container--policy {
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-top: 26px;
        border-top: 1px solid #999999;
    }

    .footer__item {
        font-size: 14px;  
    }
}

@media (max-width: 1200px) {
    .footer__max-logo {
        width: 152px;
    }
}

@media (max-width: 992px) {
    .container--footer {
        padding: 0 29px;
    }
}

@media (max-width: 767px) {
    .footer {
        flex-direction: column;
        gap: 24px;
        padding-top: 19px;
    }

    .footer__container--policy {
        margin-top: 0;
        border: none;
    }
    
    .footer__container--first {
        margin-top: 14px;
    }

    .footer__item {
        text-align: center;
    }

    .footer__max-logo {
        display: none;
    }

    .footer__min-logo {
        display: block;
    }
}

@media (max-width: 575px) {

    .footer__item {
        font-size: 18px;
    }
}